class MobileMenu {
  constructor() {
    if (document.querySelector(".js-header1")) {
      this.mediaQueryList = window.matchMedia("(max-width: 1023px)");
      this.button = document.querySelector('button[aria-controls="navi"]');
      this.menu = this.button.nextElementSibling;
      this.flg = true;
      this.nav = document.querySelector('.p-nav1');
      this.focusableElementsSelector =
        'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, [tabindex="0"], [contenteditable]';

      //初回アップデート
      this.initialize();
    }
  }

  initialize() {
    this.mediaQueryList.addEventListener("change", this.resize.bind(this));
    this.resize();
    this.toggleMenu();
  }

  // ウィンドウサイズ変更時の処理
  resize() {
    if (this.mediaQueryList.matches) {
      // SPサイズになった時の処理
      this.button.setAttribute("aria-expanded", "false");
      this.button.hidden = false;
      this.flg = true;
      // this.backgroundFix(false);
      this.button.setAttribute("aria-label", "メニューを開く");
    } else {
      // PCサイズになった時の処理
      this.button.removeAttribute("aria-expanded");
      this.button.hidden = true;
      this.flg = true;
      // this.backgroundFix(false);
      this.button.setAttribute("aria-label", "メニューを開く");
    }
  }

  // メニューの開閉処理
  toggleMenu() {
    this.button.addEventListener("click", (e) => {
      const expanded = e.currentTarget.getAttribute("aria-expanded") === "true";
      e.currentTarget.setAttribute("aria-expanded", String(!expanded));

      if (this.flg) {
        // 表示する
        // this.backgroundFix(this.flg);
        this.flg = false;
        e.currentTarget.setAttribute("aria-label", "メニューを閉じる");
      } else {
        // 非表示にする
        // this.backgroundFix(this.flg);
        this.flg = true;
        e.currentTarget.setAttribute("aria-label", "メニューを開く");
      }
    });

    // フォーカストラップ
    this.nav.addEventListener("keydown", (event) => {
      if (!this.flg) {
        if (event.key === "Tab") {
          event.preventDefault();
          const focusableElements = Array.from(
            this.nav.querySelectorAll(this.focusableElementsSelector)
          );
          const focusedItemIndex = focusableElements.indexOf(document.activeElement);
          if (event.shiftKey) {
            if (focusedItemIndex === 0) {
              // 現在のフォーカスが最初の要素の場合、最後の要素にフォーカスを移動
              focusableElements[focusableElements.length - 1].focus();
            } else {
              // 現在のフォーカスが最初の要素以外の場合、前の要素にフォーカスを移動
              focusableElements[focusedItemIndex - 1].focus();
            }
          } else {
            if (focusedItemIndex === focusableElements.length - 1) {
              // 現在のフォーカスが最後の要素の場合、最初の要素にフォーカスを移動
              focusableElements[0].focus();
            } else {
              // 現在のフォーカスが最後の要素以外の場合、次の要素にフォーカスを移動
              focusableElements[focusedItemIndex + 1].focus();
            }
          }
        }
      }
    });

    window.addEventListener("keydown", (e) => {
      //escキーでメニューを閉じる
      if (e.key === "Escape" && !this.flg) {
        this.button.setAttribute("aria-expanded", "false");
        this.button.focus();
        this.backgroundFix(false);
        this.flg = true;
        this.button.setAttribute("aria-label", "メニューを開く");
      }
    });
  }

  // メニューが出たらスクロールを停止させる
  // true：停止 / false：停止解除
  backgroundFix(bool) {
    // ナビゲーションを開いた時点でのスクロール位置を取得
    const scrollingElement = document.scrollingElement;

    // true：スクロール位置を取得 / false：bodyに設定されているtopの値（scrollingElement.scrollTop）を取得する。ないなら0
    const scrollY = bool ? scrollingElement.scrollTop : parseInt(document.body.style.top || "0");

    // 固定させる時につけるbodyのstyle
    const fixedStyles = {
      height: "100vh",
      position: "fixed",
      top: `${scrollY * -1}px`,
      left: "0",
      width: "100%",
    };

    // true：bodyにstyleを反映 / false：追加したbodyのスタイルを全部消す
    Object.keys(fixedStyles).forEach((key) => {
      document.body.style[key] = bool ? fixedStyles[key] : "";
    });

    // false:スクロール位置を元に戻す
    if (!bool) {
      window.scrollTo(0, scrollY * -1);
    }
  }
}

export default MobileMenu;
