import MobileMenu from "./modules/mobileMenu";
import ScrollAnimation from "./modules/scrollAnimation";

const init = () => {
  new MobileMenu();
  new ScrollAnimation();
};

window.addEventListener("DOMContentLoaded", () => {
  init();
});
