import {
  gsap
} from "gsap";

import {
  ScrollTrigger
} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

class ScrollAnimation {

  constructor() {
    if (document.querySelector('.p-header1')) {
      this.header1();
    }
    if (document.querySelector('body.-top')) {
      this.topPage();
    }
  }

  header1() {
    gsap.from('.p-nav1__list1 li', {
      opacity: 0,
      y: -20,
      stagger: 0.1,
      delay: 0.5,
      duration: 1.5,
      ease: 'Power3.easeOut',
    })
  }

  topPage() {
    gsap.from('.p-kv1__main1__catch1 img', {
      clipPath: 'polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)',
      opacity: 0,
      duration: 1,
      delay: 1,
      ease: 'Expo.easeOut',
    })

    gsap.from('.p-kv1__main1__text1, .p-kv1__main1__button1', {
      opacity: 0,
      y: -20,
      delay: 0.5,
      duration: 1.5,
      ease: 'Power3.easeOut',
    })

    gsap.from('.p-kv1__image1', {
      opacity: 0,
      x: 50,
      duration: 1.5,
      delay: 0.5,
      ease: 'Power3.easeOut',
    })

    const animation1 = [
      '.p-block1__head1__title1',
      '.p-block1__head1__text1',
      '.p-block2__title1',
      '.p-block2__unit1__title1__mainLabel1',
      '.p-block2__unit1__text1',
      '.p-block2__unit2__title1__mainLabel1',
      '.p-block2__unit2__main1',
      '.p-block2__unit1__image1',
      '.p-block3__text1',
      '.p-block3__button1',
      '.p-block4__title1',
      '.p-block4__main1',
      '.p-block4__unit1',
      '.p-block4__button1',
      '.p-block5__inner1'
    ];

    animation1.forEach(function (el, index) {
      gsap.from(el, {
        opacity: 0,
        y: -25,
        duration: 1.5,
        delay: 0.5,
        ease: 'Expo.easeOut',
        scrollTrigger: {
          trigger: el,
          start: 'top+=50 bottom',
        }
      });
    })

    gsap.from('.p-block1__list1 li', {
      opacity: 0,
      y: -20,
      stagger: 0.1,
      delay: 0.5,
      duration: 1.5,
      ease: 'Power3.easeOut',
      scrollTrigger: {
        trigger: '.p-block1__list1 li',
        start: 'top+=50 bottom',
      }
    })

    gsap.from('.p-block2, .p-block5', {
      opacity: 0,
      stagger: 0.1,
      delay: 0.5,
      duration: 1.5,
      ease: 'Power3.easeOut',
      scrollTrigger: {
        trigger: '.p-block2, .p-block5',
        start: 'top+=50 bottom',
      }
    })

    const animation2 = [
      '.p-block2__unit1__title1__number1',
      '.p-block2__unit2__title1__number1'
    ];

    animation2.forEach(function (el, index) {
      gsap.from(el, {
        opacity: 0,
        scale: 0,
        duration: 1,
        ease: 'Expo.easeOut',
        scrollTrigger: {
          trigger: el,
          start: 'top+=50 bottom',
        }
      });
    });
  }
}

export default ScrollAnimation;
